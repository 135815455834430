import Eatery from 'Class/Eatery';
import Globals from 'Class/Globals';

import Dialogs from 'Components/View/Dialogs';
import Footer from 'Components/View/Footer';
import Header from 'Components/View/Header';
import LeftSide from 'Components/View/LeftSide';
import Loading from 'Components/View/Loading';
import Notifications from 'Components/View/Notifications';
import TemplateAboutUs from 'Components/View/Template/AboutUs';
import TemplateAcademy from 'Components/View/Template/Academy';
import TemplateActivities from 'Components/View/Template/Activities';
import TemplateBooking from 'Components/View/Template/Booking';
import TemplateCatering from 'Components/View/Template/Catering';
import TemplateConference from 'Components/View/Template/Conference';
import TemplateContact from 'Components/View/Template/Contact';
import TemplateDefault from 'Components/View/Template/Default';
import TemplateDigitalMeetings from 'Components/View/Template/DigitalMeetings';
import TemplateEateries from 'Components/View/Template/Eateries';
import TemplateEatery from 'Components/View/Template/Eatery';
import TemplateIndependent from 'Components/View/Template/Independent';
import TemplateLocation from 'Components/View/Template/Location';
import TemplateLocations from 'Components/View/Template/Locations';
import TemplateMeetingRoom from 'Components/View/Template/MeetingRoom';
import TemplateMenu from 'Components/View/Template/MenuNew';
import TemplateMenuGarden from 'Components/View/Template/MenuGarden';
import TemplateNews from 'Components/View/Template/News';
import { TemplateSingle } from 'Components/View/Template/News/Single';
import TemplateParty from 'Components/View/Template/Party';
import TemplatePartyBooking from 'Components/View/Template/PartyBooking';
import TemplatePost from 'Components/View/Template/Post';
import TemplatePress from 'Components/View/Template/Press';
import TemplatePressRelease from 'Components/View/Template/PressRelease';
import TemplatePropertyOwners from 'Components/View/Template/PropertyOwners';
import TemplateReservation from 'Components/View/Template/Reservation';
import TemplateReservationConf from 'Components/View/Template/ReservationConf';
import TemplateStudio from 'Components/View/Template/Studio';
import TemplateText from 'Components/View/Template/Text';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './core.scss';

class Core extends React.Component {
    constructor(props) {
        super(props);

        this.GA = '';
        this.GTM = '';
        this.IE = /*@cc_on!@*/false || !!document.documentMode;
        this.Navigations = 0;
        this.Router = false;
        this.Touch = 'ontouchstart' in window;
        this.Tracking = !!this.GA;

        this.state =
            {
                error: false,
                loading: true,
            };
    }

    componentDidMount() {
        this.setState({
            error: Globals.Var('error'),
            loading: Globals.Var('loading'),
        });

        Globals.Listen('add-tracking', this.AddTracking);
        Globals.Listen('redirect', this.OnRedirect);
        Globals.Listen('var-error', this.OnError);
        Globals.Listen('var-loading', this.OnLoading);
        Eatery.Load();

        if (Globals.Var('tracking')) {
            this.AddTracking();
        }
    }

    componentWillUnmount() {
        Globals.Remove('add-tracking', this.AddTracking);
        Globals.Remove('redirect', this.OnRedirect);
        Globals.Remove('var-error', this.OnError);
        Globals.Remove('var-loading', this.OnLoading);
    }

    AddTracking = () => {
        const FB = document.createElement('script');

        FB.type = 'text/javascript';
        ;
        FB.text = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '721664379232134');
            fbq('track', 'PageView');
        `;

        document.body.appendChild(FB);
    };

    OnError = (error) => {
        this.setState({error});
    };

    OnLoading = (loading) => {
        this.setState({loading});
    };

    /**
     * Callback when the user navigates.
     *
     * @param object location - Browser location object.
     *
     * @return void
     */

    OnNavigation = (location) => {
        Globals.Trigger('navigation', location);
        Globals.Var('location', location ? location.pathname || '/' : '/');

        if (document.activeElement) {
            document.activeElement.blur();
        }

        window.scrollTo(0, 0);
        this.Navigations++;
    };

    /**
     * Make redirect.
     *
     * @return void
     */

    OnRedirect = (uri) => {
        this.Router.history.push(uri);
    };

    /**
     * Add navigation listener on Router mount.
     *
     * @return void
     */

    OnRouter = (router) => {
        if (!router) {
            return;
        }

        this.Router = router;
        router.history.listen(this.OnNavigation);
    };

    /**
     * Confirm navigation. Used when a user tries no navigate away from unsaved content.
     * https://reacttraining.com/react-router/web/api/BrowserRouter/getuserconfirmation-func
     *
     * This functionality is triggered by rendering <Prompt> (from react-router) in sub components
     * when they have unsaved content.
     *
     * @param string|jsx message - Confirmation message.
     * @param function callback - Callback to confirm/deny navigation.
     *
     * @return void
     */

    OnUserConfirmation = (message, callback) => {};

    /**
     * Render a page depending on the current URI.
     *
     * @return JSX|string - The page component or an empty string when unloaded.
     */

    RouteRenderPage = (routeProps) => {
        const Uri = '/' + routeProps.location.pathname.substr(1) || '/';
        const Page = Eatery.Page(Uri);

        if (!Page) {
            return '';
        }

        const {template, title, content} = Page;

        Globals.SetTitle(title);

        let eateryGarden = false;
        if (content.eatery && content.eatery.length > 0) {
            const eateryUri = content.eatery[0]; // Assuming the first eatery is the relevant one
            const relatedEatery = Eatery.Page(eateryUri);
            eateryGarden = relatedEatery?.content?.eatery_garden || false;
        }

        switch (template) {
            case 'about-us':
                return <TemplateAboutUs key={Uri} {...Page} />;
            case 'academy':
                return <TemplateAcademy key={Uri} {...Page} />;
            case 'activities':
                return <TemplateActivities key={Uri} {...Page} />;
            case 'catering':
                return <TemplateCatering key={Uri} {...Page} />;
            case 'conference':
                return <TemplateConference key={Uri} {...Page} />;
            case 'party_booking':
                return <TemplatePartyBooking key={Uri} {...Page} />;
            case 'contact':
                return <TemplateContact key={Uri} {...Page} />;
            case 'digital-meetings':
                return <TemplateDigitalMeetings key={Uri} {...Page} />;
            case 'eateries':
                return <TemplateEateries key={Uri} {...Page} />;
            case 'eatery':
                return <TemplateEatery key={Uri} {...Page} />;
            case 'independent':
                return <TemplateIndependent key={Uri} {...Page} />;
            case 'locations':
                return <TemplateLocations key={Uri} {...Page} />;
            case 'party':
                return <TemplateParty key={Uri} {...Page} />;
            case 'meetingroom':
                return <TemplateMeetingRoom key={Uri} {...Page} />;
            case 'menu':
                if (eateryGarden) {
                    return <TemplateMenuGarden key={Uri} {...Page} />;
                } else {
                    return <TemplateMenu key={Uri} {...Page} />;
                }
            case 'post':
                return <TemplatePost key={Uri} {...Page} />;
            case 'press':
                return <TemplatePress key={Uri} {...Page} />;
            case 'news':
                return <TemplateNews key={Uri} {...Page} />;
            case 'single-news':
                return <TemplateSingle key={Uri} {...Page} />;
            case 'property-owners':
                return <TemplatePropertyOwners key={Uri} {...Page} />;
            case 'reservation':
                return <TemplateReservation key={Uri} {...Page} />;
            case 'reservationConf':
                return <TemplateReservationConf key={Uri} {...Page} />;
            case 'studio':
                return <TemplateStudio key={Uri} {...Page} />;
            case 'text':
                return <TemplateText key={Uri} {...Page} />;
            default:
                if (template) {
                    console.error('Unknown template:', template);
                }

                return <TemplateDefault key={Uri} {...Page} />;
        }
    };

    RouteRenderBooking = (routeProps) => {
        const {stage, type} = routeProps.match.params;
        return <TemplateBooking stage={stage} type={type} />;
    };

    RouteRenderLocation = (routeProps) => {
        const {eatery} = routeProps.match.params;
        const Location = Eatery.Eatery(`/${eatery}`);
        if (!Location) {
            return this.RouteRenderPage(routeProps);
        }
        return <TemplateLocation key={eatery} {...Location} />;
    };

    RouteRenderPress = (routeProps) => {
        const {id} = routeProps.match.params;
        return <TemplatePressRelease key={id} id={id} />;
    };

    /**
     * Render a meeting room page.
     *
     * @return JSX|string - The page component or an empty string when unloaded.
     */

    RouteRenderMeetingRoom = (routeProps) => {
        const {id} = routeProps.match.params;
        const MeetingRoom = Eatery.MeetingRoom(id);
        if (!MeetingRoom) {
            return this.RouteRenderPage(routeProps);
        }
        const {title} = MeetingRoom;
        Globals.SetTitle(title);
        return <TemplateMeetingRoom key={id} {...MeetingRoom} />;
    };

    /**
     * Render a menu page.
     *
     * @return JSX|string - The page component or an empty string when unloaded.
     */

    RouteRenderMenu = (routeProps) => {
        const {id} = routeProps.match.params;
        const Id = parseInt(id, 10);
        const Menu = Eatery.Menu(Id);
        if (!Menu) {
            return this.RouteRenderPage(routeProps);
        }
        const {title} = Menu;
        Globals.SetTitle(title);
        return <TemplateMenu key={Id} {...Menu} />;
    };

    RouteRenderNews = (routeProps) => {
        const {id} = routeProps.match.params;
        const Uri = Eatery.PostSearch(id);
        const Post = Eatery.Post(Uri);
        if (!Post) {
            return this.RouteRenderPage(routeProps);
        }
        if (Uri !== Globals.Var('location')) {
            setTimeout(() => this.OnRedirect(Uri), 0);
        }
        const {title} = Post;
        Globals.SetTitle(title);
        return <TemplatePost key={id} {...Post} />;
    };

    /**
     * Render a studio page.
     *
     * @return JSX|string - The page component or an empty string when unloaded.
     */

    RouteRenderStudio = (routeProps) => {
        const {id} = routeProps.match.params;
        const Studio = Eatery.Studio(id);
        if (!Studio) {
            return this.RouteRenderPage(routeProps);
        }
        const {title} = Studio;
        Globals.SetTitle(title);
        return <TemplateStudio key={id} {...Studio} />;
    };

    render() {
        const {error, loading} = this.state;
        if (loading) {
            return <Loading />;
        }
        const CA = ['Core'];
        const Content = [<Header key="header" loading={loading} />];
        if (this.IE) {
            CA.push('IsIE');
        } else {
            CA.push('NotIE');
        }
        if (this.Touch) {
            CA.push('Touch');
        } else {
            CA.push('NoTouch');
        }
        if (!error && !loading) {
            Content.push(<LeftSide key="leftSide" />);
            Content.push(
                <Switch key="switch">
                    <Route
                        path="/anlaggningar/:eatery"
                        render={this.RouteRenderLocation}
                    />
                    <Route
                        path="/bokning/:type/:stage?"
                        render={this.RouteRenderBooking}
                    />
                    <Route
                        path="/meny/:id/:name?"
                        render={this.RouteRenderMenu}
                    />
                    <Route
                        path="/motesrum/:id/:name?"
                        render={this.RouteRenderMeetingRoom}
                    />
                    <Route
                        path="/nyhet/:id/:title?"
                        render={this.RouteRenderNews}
                    />
                    <Route path="/press/:id" render={this.RouteRenderPress} />
                    <Route
                        path="/studio/:id/:name?"
                        render={this.RouteRenderStudio}
                    />
                    <Route render={this.RouteRenderPage} />
                </Switch>,
            );
            Content.push(<Footer key="footer" />);
        } else if (!loading) {

        }
        return (
            <Router
                getUserConfirmation={this.OnUserConfirmation}
                ref={this.OnRouter}
            >
                <div className={CA.join(' ')}>
                    {Content}
                    <Dialogs />
                    <Notifications />
                </div>
            </Router>
        );
    }
}

export default Core;
