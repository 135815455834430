import React from "react";
import "./reservation.scss";

import Eatery from "Class/Eatery";
import Parser from "Class/Parser";

import Button from "Components/UI/Button";
import Link from "Components/UI/Link";
import LocalLogo from "Components/Layout/LocalLogo";
import Modules from "Components/View/Modules";
import Segment from "Components/Layout/Segment";

class TemplateReservationConf extends React.Component
{
    render()
    {
        const {content, title} = this.props;
        const {about, contents} = content || {};
        const Eateries = Eatery.Eateries();
        const Items = [];

        for (let uri in Eateries)
        {
            const {content, title} = Eateries[uri];
            const {bookingUrlConf} = content || {};

            if (!bookingUrlConf)
            {
                continue;
            }

            Items.push(
                <div className="TemplateReservationItemWrapper" key={uri}>
                    <Link className="TemplateReservationItem ColorBeige" href={bookingUrlConf}>
                        <LocalLogo name={title} uri={uri}/>
                        <div className="TemplateReservationItemButton ColorBlack">Boka nu</div>
                    </Link>
                </div>
            );
        }

        return (
            <div className="TemplateReservation">
                <Segment top={true}>
                    <div className="TemplateReservationTop">
                        <h1>{title}</h1>
                        {Parser.Parse(about, "div", null, {className: "TemplateReservationContent"})}
                    </div>
                    <div className="TemplateReservationItemsWrapper">
                        <div className="TemplateReservationItems">{Items}</div>
                        <Button color="Black" to="/har-finns-vi">Se alla Eaterys</Button>
                    </div>
                </Segment>
                <Modules modules={contents}/>
            </div>
        );
    }
}

TemplateReservationConf.defaultProps =
{
    content: {}
};

export default TemplateReservationConf;