import React from "react";
import "./reservation.scss";

import Eatery from "Class/Eatery";
import Links from "Components/UI/Links";
import LocalLogo from "Components/Layout/LocalLogo";

class ReservationConf extends React.Component
{
    render()
    {
        const Eateries = Eatery.Eateries();
        const Items = [];

        for (let uri in Eateries)
        {
            const {content, title} = Eateries[uri];
            const {bookingUrlConf} = content || {};

            if (!bookingUrlConf)
            {
                continue;
            }

            Items.push({
                label: <LocalLogo name={title} uri={uri}/>,
                type: "link",
                url: bookingUrlConf
            });
        }

        return (
            <div className="Reservation">
                <h3>Boka konferens</h3>
                <Links columns={2} items={Items}/>
            </div>
        );
    }
}

export default ReservationConf;